<template>
    <div>
        <v-container class="ml-10" align="center">
            <v-sheet class="mt-10" color="white" align="center" tile>
                <v-row class="fill-height" align="center" justify="center">
                    <v-col cols="12" sm="12">
                        <v-title class="display-1"
                                 style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;">{{ airportData.name }}</v-title>
                        <br />
                        <p style="font-weight: bold;margin-top:5px">
                            {{ airportData.branchAddress }} uyuyturytyretyuyrtuyeruty
                        </p>
                    </v-col>
                </v-row>
            </v-sheet>

            <template>
                <p>{{ AirportName }}</p>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-card color="#006400" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <p font style="font-size:16px; color:white;">
                                                    {{$t('total_no_of_flights_day')}}
                                                </p>
                                                <v-icon color="white" size="25">flight</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right" justify="center">
                                            <div>

                                                <v-list-item-subtitle class="white--text"
                                                                      style="font-size: 26px">
                                                    {{
                              flightIncomingOutgoing.daily.TotalFlight
                                                    }}
                                                </v-list-item-subtitle>


                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-card color="#FFA500" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <p style="font-size:16px; color:white;">
                                                    {{$t('total_no_of_arrival_flights_day')}}
                                                </p>
                                                <v-icon color="white" size="25">flight_land</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>


                                                <v-list-item-subtitle class="white--text"
                                                                      style="font-size: 26px">
                                                    {{
                              flightIncomingOutgoing.daily.TotalIncoming
                                                    }}
                                                </v-list-item-subtitle>


                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-card color="#077dfa" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <p style="font-size:16px; color:white;">
                                                    {{$t('total_no_of_departure_flights_day')}}
                                                </p>
                                                <v-icon color="white" size="25">flight_takeoff</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>

                                                <v-list-item-subtitle class="white--text"
                                                                      style="font-size: 26px">
                                                    {{
                              flightIncomingOutgoing.daily.TotalOutGoing
                                                    }}
                                                </v-list-item-subtitle>


                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-card color="#8B0000" elevation="5" style="height:180px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <div>
                                                <p style="font-size:16px; color:white;">
                                                    {{$t('count_of_flight_type_month')}}
                                                </p>

                                                <v-icon color="white" size="25">flight_class</v-icon>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item three-line>
                                        <v-list-item-content class="text-right">
                                            <div>

                                                <v-list-item-subtitle class="white--text"
                                                                      style="font-size: 26px">
                                                    {{
                              flightIncomingOutgoing.monthly.TotalFlighInMonth
                                                    }}
                                                </v-list-item-subtitle>


                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <!--<v-col
                      cols="6"
                      v-for="title in titles"
                      v-bind:key="title.link"
                      class="viewCard"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <router-link :to="title.link">
                            <v-card
                              :elevation="10"
                              class="blue darken-1 text-center"
                              height="100"
                              width="100%"
                            >
                              <div>
                                <v-list-item three-line>
                                  <v-list-item-content>
                                    <v-icon color="green" class="mt-2 mr-2"
                                      >fas fa-sort-up</v-icon
                                    >
                                    <v-list-item-title
                                      class="white--text subtitle-1 mt-1 pa-4"
                                    >
                                      {{ title.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                      class="white--text header-6 mt-1"
                                    >
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                            </v-card>
                          </router-link>
                        </template>
                      </v-tooltip>
                    </v-col>-->
                </v-row>
            </template>
            <template>
                <v-sheet class="mt-5" color="white" align="center" tile>
                    <v-row class="fill-height" align="center" justify="center">
                        <v-col cols="12" sm="12">

                            <img :src="'/'+airportData.logo"
                                 style="width: 100%; height: 550px; object-fit: fill;" />
                        </v-col>
                    </v-row>
                </v-sheet>
            </template>

            <template>
                <v-row>

                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('monthly_performance_of_airport')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="LineChart"
                                            :data="monthlyData"
                                            :options="chartOptions"
                                            :marker="marker"
                                            :key="reload"
                                            style="height: 450px" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5" style="height:531px">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('employees_of_airport')}}
                            </h1>
                            <v-card>
                                <v-list style="background-color: #1976d2">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title id="table"
                                                               align="center"
                                                               class="white--text">
                                                <strong>{{ $t("employee_name") }}</strong>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("address") }}</strong></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="center">
                                            <v-list-item-title id="table" class="white--text">
                                                <strong>
                                                    {{$t("type")}}
                                                </strong>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-content align="right">
                                            <v-list-item-title id="table" class="white--text">
                                                <strong>
                                                    {{$t("contact_no")}}
                                                </strong>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                            <v-card style="overflow-y: scroll; height: 350px">
                                <v-card style="height: 350px;">
                                    <v-list v-for="(airEmployee, i) in airEmployeeDetails"
                                            :key="i">
                                        <v-list-item :style="{ background: airEmployee.backgroundColor }">
                                            <v-list-item-avatar>
                                                <v-title id="table" class="black--text">
                                                    {{i + 1}}
                                                </v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title id="table"
                                                                   align="center"
                                                                   class="black--text">{{ airEmployee.empName }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table"
                                                                   class="mr-18 black--text">{{ airEmployee.empAddress }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text">
                                                    {{ airEmployee.empType }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text">
                                                    {{ airEmployee.empPhone }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </v-list>
                                </v-card>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('departure_flights')}}
                            </h1>
                            <template>

                                <GChart type="PieChart"
                                        :options="options"
                                        :data="data"
                                        height="500"
                                        align="center" />
                            </template>
                        </v-card>
                    </v-col>

                </v-row>
            </template>
            <template>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                {{$t('performance_of_airport')}}
                            </h1>
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="chartData"
                                            :key="reload"
                                            :options="chartOptions"
                                            style="height: 350px" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>

                    <!--<v-col cols="12" sm="6">
                        <v-card class="pa-5" elevation="5">
                            <h1 class="headline mb-2 primary--text"
                                style="border-bottom: 1px solid grey">
                                Daily Domestic Passenger
                            </h1>
                            <v-dialog max-width="1000px" v-model="dialogCreate">
                                <template v-slot:activator="{ on }">
                                    <v-btn :success="success"
                                           class=" clickbtn mb-2 float-right"
                                           color="primary"
                                           dark
                                           v-on="on">
                                        Add Domestic Passenger
                                    </v-btn>
                                </template>
                                <createDivisionForm :success="success"
                                                    @formResponse="onResponse" />
                            </v-dialog>
                            <v-dialog max-width="1000px" v-model="dialogEdit">
                                <editDivisionForm :serviceID="editedData.serviceID"
                                                  :success="success"
                                                  @formResponse="onResponse"
                                                  v-if="dialogEdit" />
                            </v-dialog>
                            <v-dialog max-width="500px" v-model="dialogDelete">
                                <deleteListData :deleteFormData="deleteFormData"
                                                @formResponse="onResponse"></deleteListData>
                            </v-dialog>
                            <v-expansion-panels hover>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Filter</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-container fluid>
                                            <v-row align="start">
                                                <v-col class="float-right" cols="12" sm="6">
                                                    <v-autocomplete :items="airlineList"
                                                                    item-value="id"
                                                                    item-text="value"
                                                                    v-model="serverParams.airlineName"
                                                                    label="Airline Name"
                                                                    clearable
                                                                    required></v-autocomplete>
                                                </v-col>
                                                <v-col class="float-right" cols="12" sm="6">
                                                    <v-autocomplete :items="['Arrival', 'Departure']"
                                                                    v-model="serverParams.arrivalDeparture"
                                                                    label="ArrivalDeparture"
                                                                    clearable
                                                                    required></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" justify="center">
                                                <v-btn @click="searchInput"
                                                       small
                                                       color="primary"
                                                       dark>
                                                    <i class="material-icons">search</i>Search
                                                </v-btn>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>


                            <vue-good-table :columns="columns"
                                            :pagination-options="{
                                                enabled: true,
                                                mode: 'records',
                                                perPageDropdown: [5,10, 15,20]
                                              }"
                                            :rows="rows"
                                            :totalRows="totalRecords"
                                            @on-column-filter="onColumnFilter"
                                            @on-page-change="onPageChange"
                                            @on-per-page-change="onPerPageChange"
                                            @on-sort-change="onSortChange"
                                            mode="remote">
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'actions'">
                                        <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                                        <v-icon @click="deleteItem(props.row)" small>delete</v-icon>
                                    </span>
                                </template>
                            </vue-good-table>
                        </v-card>
                    </v-col>-->

                </v-row>
            </template>
        </v-container>
    </div>

</template>
<script>
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import axios from "axios";
    import { parseJwt } from "@/mixins/parseJwt";
    //import createDivisionForm from "../DomesticPassenger/create.vue";
    //import deleteListData from "../../../components/deleteModal.vue";
    //import editDivisionForm from "../DomesticPassenger/edit.vue";
    import publicAxios from "../../../simple-axios";
    import { mapGetters } from "vuex";

    export default {
        name: "BuddhaAir",
        components: {
            //createDivisionForm,
            //editDivisionForm,
            //deleteListData,
        },
        computed: {
            ...mapGetters(["getSystemUserData"]),


            AirportName() {
                return this.getSystemUserData.name;
            },
        },
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true,
            },
        },
        data() {
            return {
                reload: 0,
                airlineList: [],
                airEmployeeDetails: [],
                data: [],
                options: {
                    height: 450,
                    is3d: true,
                },

                callbackResponse: {
                    timeout: 6000,
                },
                success: false,
                flightIncomingOutgoing: [],
                dialogEdit: false,
                dialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                provinceId: [],
                deleteFormData: {},
                popout: true,
                titles: [
                    {
                        name: "Generate Report",
                        link: "/report",
                    },
                    {
                        name: "Profile",
                        link: "/branch",
                    },
                ],
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                dialogCreate: false,
                slides: ["", "", "", ""],
                chartData: [],

                monthlyData: [],

                series: [
                    56090,
                    44961,
                    30200,
                    44336,
                    20350,
                    56210,
                    44219,
                    30212,
                    44320,
                    20120,
                    1200,
                    2130,
                    1239,
                    1236,
                    2810,
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                chartsOptions: {
                    chart: {
                        width: 800,
                        type: "donut",
                    },
                    labels: [
                        "Pokhara",
                        "Kathmandu",
                        "Rajbiraj",
                        "Bhairahawa",
                        "Biratnagar",
                        "Bharatpur",
                        "Bhadrapur",
                        "Dhangadhi",
                        "Tumlingtar",
                        "Nepalgunj",
                        "Surkhet",
                        "Janakpur",
                        "Simara",
                        "Varanasi",
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                columns: [
                    {
                        label: "S No.",
                        field: "countIndex",
                    },
                    {
                        label: "Airlines Name",
                        field: "airlineName",
                    },
                    {
                        label: "Arrival Departure",
                        field: "arrivalDeparture",
                    },
                    {
                        label: "Number Of Person",
                        field: "numberOfPerson",
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        sortable: false,
                    },
                ],

                rows: [],
                airportData: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "asc",
                        },
                    ],
                    page: 1,
                    perPage: 5,
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: "",
                },
                desserts: [
                    {
                        sno: 1,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 60,
                        departureTime: "08:30 A.M",
                        arrivalTime: "10:00 A.M",
                    },
                    {
                        sno: 2,
                        airportName: "Yeti Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 40,
                        departureTime: "02:15 P.M",
                        arrivalTime: "3:45 P.M",
                    },
                    {
                        sno: 3,
                        airportName: "Guna Airlines",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 20,
                        departureTime: "04:45 P.M",
                        arrivalTime: "06:25 P.M",
                    },
                    {
                        sno: 4,
                        airportName: "Saurya Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 15,
                        departureTime: "06:25 P.M",
                        arrivalTime: "08:05 P.M",
                    },
                    {
                        sno: 5,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 20,
                        departureTime: "07:00 P.M",
                        arrivalTime: "08:30 P.M",
                    },
                ],
                headers: [
                    { text: "S.No", value: "sno" },
                    {
                        text: "Airport Name",
                        align: "start",
                        sortable: false,
                        value: "airportName",
                    },
                    { text: "Arrival Departure", value: "arrivalDeparture" },
                    { text: "Number of Person", value: "numberOfPerson" },
                    { text: "Departure Time", value: "departureTime" },
                    { text: "Arrival Time", value: "arrivalTime" },
                    { text: "Actions", value: "actions", sortable: false },
                ],
            };
        },
        mounted() {
            this.getCompleteData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            async getAirportBranchId() {

                const { data } = await publicAxios.post("HotelDetails/GetIndividualHotelDetails/" + this.$route.params.airportID)
                this.airportData = data
                console.log('this.AirportDetailsData', this.airportData)

            },
            getCompleteData() {
                this.loadItems();
                this.getAirportIncomingOutgoing();
                this.getBarAirportPerformanceIncomingandOutgoing();
                this.getBarAirportMonthlyPerformance();
                /*this.getAirportDetail();*/
                this.getAirportBranchId();
                this.getPieAirportMonthlyPerformance();
                this.formData();
                this.BarAirportPerformanceIncomingandOutgoing();
                this.getAirEmployeeDetails();
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true;
                    this.loadItems();
                    this.reloadPage();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false;
                }
            },
            async formData() {
                axios
                    .get("Airport/DDLAirlineListAsync")
                    .then((response) => {
                        this.airlineList = response.data;
                    })
                    .catch((error) => console.log(error.response));
            },
            editItem(props) {
                this.editedData.serviceID = props.id;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url =
                    "Airport/DeleteDomesticPassengerByIDAsync/" + props.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field,
                        },
                    ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    AirportName: this.serverParams.airlineName,
                    Groupby: this.serverParams.arrivalDeparture,
                };
                axios
                    .post("Airport/GetDomesticPassengerDetailListAsync", param)
                    .then((response) => {
                        this.rows = response.data.data;
                        console.log("this.rows", this.rows);
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async getAirportDetail() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportDetailsByBranchID"
                );
                this.airportData = data;
                console.log("this.airportData", this.airportData);
            },
            //async getBarAirportPerformanceIncomingandOutgoing() {
            //    setInterval(async () => {
            //        const { data } = await publicAxios.get('Airport/GetAirportAirlineDetailsByBranchAsync')
            //        let formatedData = this.getChartData(data)
            //        this.chartData = formatedData
            //    }, 4000);

            //},
            async getBarAirportPerformanceIncomingandOutgoing() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportAirlineDetailsByBranchAsync/" + this.$route.params.airportID
                );
                let formatedData = this.getChartData(data);
                this.chartData = formatedData;
                console.log('this.AirportAirlines', this.chartData);
            },
            async getBarAirportMonthlyPerformance() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportPassengerCountAsync/" + this.$route.params.airportID
                );
                let formatedData = this.getChartData(data);
                this.monthlyData = formatedData;
            },

            async getPieAirportMonthlyPerformance() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportDepartureFlights/" + this.$route.params.airportID
                );
                let formatedData = this.getChartData(data);
                this.data = formatedData;
            },

            //IncomingandOutgoing: function () {
            //    const { data } = await publicAxios.get("Airport/GetAirportFlightCountByBranchAsync")
            //    this.flightIncomingOutgoing = data
            //    console.log('this.flightIncomingOutgoing', this.flightIncomingOutgoing)
            //},
            async getAirportIncomingOutgoing() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportFlightCountByBranchAsync/" + this.$route.params.airportID
                );
                this.flightIncomingOutgoing = data;
                console.log('this.flightIncomingOutgoing', this.flightIncomingOutgoing);
            },
            async getAirEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirEmployeeDetails/" + this.$route.params.airportID
                );
                this.airEmployeeDetails = data;
                console.log("this.airEmployeeDetails", this.airEmployeeDetails);
            },

            reloadPage() {
                this.getCompleteData();
                this.reload += 1;
                //this.$router.go();
            },
            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },
        },

        created() {
            //this.getAirportIncomingOutgoing()
            this.getAirEmployeeDetails();
        }
        //created() {
        //    this.getAirportIncomingOutgoing()
        //}
    };
</script>
<style>
    /*    @media screen and (max-width:1903px) {
        #sideBox {
            font-size: 10px !important;
        }
    }*/
</style>
